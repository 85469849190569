<template>
    <v-card flat>
        <v-card-title>
            <span class="secondary--text font-weight-bold">Table access</span>
        </v-card-title>
        <v-card-text>
            <v-btn class="mr-2 mb-6 font-weight-bold" color="primary" @click="generateToken()">Generate New Token</v-btn>
            <v-row justify="center" align="center">
                <v-col cols="auto">
                    <div class="d-flex align-center">
                        <v-icon left>mdi-key-variant</v-icon>
                        Current Token
                    </div>
                </v-col>
                <v-col>
                    <div class="d-flex align-center">
                        <v-text-field
                            :append-icon="show ? 'visibility' : 'visibility_off'"
                            :type="show ? 'text' : 'password'"
                            :value="snowflakeTokens.access_token"
                            class="input-group--focused mr-2"
                            @click:append="show = !show"
                            :loading="loading"
                            :disabled="loading"
                            outlined
                            dense
                            hide-details></v-text-field>
                        <copy-to-clipboard
                            :textToCopy="snowflakeTokens.access_token"
                            buttonClass="caption font-weight-bold"
                            buttonColor="secondary"
                            buttonName="copy to clipboard"
                            :iconButton="true"
                            :disabled="loading"></copy-to-clipboard>
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'
const CopyToClipboard = () => import('@/components/CopyToClipboard')

export default {
    components: {
        CopyToClipboard
    },
    data() {
        return {
            dialog: false,
            token: '',
            show: false,
            loading: false
        }
    },
    methods: {
        generateToken: function () {
            this.loading = true
            this.$axios
                .post('/tokens/snowflake_access')
                .then(response => {
                    this.$store.dispatch('userStore/fetchSnowflakeTokens')
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'New token has been generated!',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                    this.loading = false
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Failed to generate new token',
                        snackBarTimeout: 10000,
                        snackBarIcon: 'error'
                    })
                    this.loading = false
                })
        }
    },
    computed: {
        ...mapState('userStore', ['snowflakeTokens'])
    },
    mounted() {
        this.$store.dispatch('userStore/fetchSnowflakeTokens')
    }
}
</script>
